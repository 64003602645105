var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f2e85f69d3f2a7d78fa855ea289b7c266cd32150"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  captureConsoleIntegration,
  extraErrorDataIntegration,
} from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";
import { SENTRY_RELEASE } from "./sentryRelease";

const createIntegrations = () => {
  const baseIntegrations = [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    captureConsoleIntegration({ levels: ["error"] }),
    extraErrorDataIntegration({ depth: 9 }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: true,
      networkDetailAllowUrls: ["annotation.characterai.dev/v1/tasks/"],
    }),
  ];

  return baseIntegrations;
};

Sentry.init({
  dsn: "https://a8aafd2bcea59666cf69a47b86d1f5a3@o4504695552606208.ingest.us.sentry.io/4507816550203392",
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1,
  profilesSampleRate: 1,
  sampleRate: 1,
  normalizeDepth: 10,
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
  integrations: createIntegrations(),
  release: SENTRY_RELEASE ?? "localhost",
});
